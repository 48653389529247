import { HttpMethods, sendRequest, LoginType, getPreBootstrapConfig } from "../util/ApiHelper";

export const getUserIdentityProvider = async (email: string) => {
    const preBootstrapConfig = await getPreBootstrapConfig();
    return await sendRequest(
        HttpMethods.GET,
        `${preBootstrapConfig.MOBILE_BOOTSTRAP_API_BASE_ADDRESS}Settings/GetUserIdentityProvider/?queryEmail=`+encodeURIComponent(email),
        {}, {},
        true,
        LoginType.Portal
    );
};



